<template>
  <div>
    <KTCodePreview v-bind:title="'In mã vạch'">
      <template v-slot:toolbar>
        <button
          type="button"
          class="btn btn-primary font-weight-bolder btn-sm"
          @click="printData"
          style="width: max-content"
        >
          <i class="fas fa-print"></i>
          In mã vạch
        </button>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <div id="printMe">
          <div v-for="(group, index) in listProduct" :key="index">
            <div
              class="item-print"
              v-for="(product, count) in group"
              :key="count"
              style="background: #ffffff"
            >
              <div>
                <barcode
                  v-bind:value="product.barCode"
                  :width="1.3"
                  :height="product.barCodeHeight"
                  :fontSize="12"
                  :marginBottom="3"
                >
                </barcode>
                <p class="content-info font-size-print mt-0">
                  <span class="content-info font-size-print">{{
                    product.productName
                  }}</span>
                </p>
                <p class="content-info">
                  <span class="label-color font-size-price"
                    >{{ convertPrice(product.listedPrice) }} VNĐ</span
                  >
                </p>
              </div>
              <br />
            </div>
            <br />
          </div>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import VueBarcode from 'vue-barcode';
import { makeToastFaile } from '../../../utils/common';

export default {
  props: ['data'],
  data() {
    return {
      barCode: '',
      listProduct: [],
    };
  },
  created() {
    if (!this.data) {
      return makeToastFaile('Chưa có dữ liệu!');
    }
    this.genData();
  },
  methods: {
    printData: function () {
      if (!document.getElementById('printMe')) {
        return;
      }
      const prtHtml = document.getElementById('printMe').innerHTML;

      const width = this.data.type === 1 ? 105 : 70;
      const marginLeft = this.data.type === 1 ? 0 : 2;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <link rel="stylesheet" href="main.css" />
          <link rel="stylesheet" media="print" href="print.css" />
          ${stylesHtml}
          <style>
          @page {
            size: ${width}mm 22mm;
            margin: 0;
            }
          @media print {
            html,
            body {
              width: ${width}mm;
              height: 22mm;
              display: block !important;
              line-height: 1.2 !important;
            }
          }
          .item-print {
                width: 195px !important;
                // height: 22mm !important;
                display: inline-flex;
                margin-left: ${marginLeft}px;
                }
          .content-info {
            color: black;
            font-weight: 500;
            text-align: center;
            margin-bottom: 0px;
            padding: 0 9px 0 9px
          }
          .vue-barcode-element{
            width: 200px !important;
          }
          .font-size-print {
            font-size: 11px !important;
          }
          .font-size-price {
            font-size: 13px !important;
          }
          </style>
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    genData() {
      const limit = this.data.type === 1 ? 3 : 2;
      const listItem = this.data.listItem ? this.data.listItem : [];
      const listProductPrint = [];
      listItem.forEach((element) => {
        const quantity = parseInt(element.quantity);
        for (let index = 0; index < quantity; index++) {
          const barCodeHeight = element.productName.length < 50 ? 28 : 20;
          const item = {
            id: element.id,
            barCode: element.barCode,
            productName: element.productName,
            listedPrice: element.listedPrice,
            barCodeHeight: barCodeHeight,
          };
          listProductPrint.push(item);
        }
      });
      const numPage = Math.ceil(listProductPrint.length / limit);
      this.listProduct = [];
      for (let page = 1; page <= numPage; page++) {
        const startIndex = limit * (page - 1);
        const endIndex = page * limit;
        const groupItem = listProductPrint.slice(startIndex, endIndex);
        this.listProduct.push(groupItem);
      }
      this.printData();
    },
  },
  components: {
    KTCodePreview,
    barcode: VueBarcode,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'In sản phẩm', route: '/products/print-product' },
      { title: 'In mã vạch' },
    ]);
  },
};
</script>

<style scoped>
.header-print {
  text-align: center;
  width: 100%;
}
.label-color {
  color: black;
  font-weight: 600;
  text-align: center;
}
.content-info {
  color: black;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0px;
}
.font-size-print {
  font-size: 11px !important;
}
.font-size-price {
  font-size: 13px !important;
}
.item-print {
  width: 195px !important;
  /* height: 22mm !important; */
  display: inline-flex;
}
</style>
